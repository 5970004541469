<template>
  <div>
    <v-card outlined>
      <v-form ref="itemForm">
        <v-card-text class="pa-0 px-3">
          <v-container fluid class="pa-0 px-3">
            <v-row>
              <v-overlay :absolute="true" :value="itemOverlay">
                <v-progress-circular
                  indeterminate
                  size="32"
                ></v-progress-circular>
              </v-overlay>
              <v-col cols="12" sm="6" class="pl-2 pr-7 pb-2">
                <v-text-field
                  autofocus
                  :label="$t('user.machines.machineNameLabel')"
                  :disabled="itemOverlay"
                  v-model="item.machineName"
                  :rules="itemInputRules"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="pl-2 pr-7 pb-2">
                <v-text-field
                  autofocus
                  :label="$t('sawCalc.inputMachine.manufacturerLabel')"
                  :disabled="itemOverlay"
                  v-model="item.manufacturerName"
                  :rules="itemInputRules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pl-2 pr-7 pb-2">
                <v-text-field
                  :label="$t('sawCalc.inputMachine.modelLabel')"
                  :disabled="itemOverlay"
                  v-model="item.model"
                  :rules="itemInputRules"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="5" class="px-2 pr-7 pt-0">
                <v-autocomplete
                  :label="$t('sawCalc.inputMachine.bladeWidthMetricLabel')"
                  :items="bladeWidthMetricList"
                  item-text="text"
                  item-value="value"
                  :disabled="itemOverlay"
                  v-model="item.bladeWidthMetric"
                  @change="bladeWidthMetricChanged"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="5" class="px-2 pr-7  pt-0">
                <v-autocomplete
                  :label="$t('sawCalc.inputMachine.bladeThicknessMetricLabel')"
                  :items="bladeThicknessMetricList"
                  :disabled="itemOverlay"
                  v-model="item.bladeThicknessMetric"
                  @change="bladeThicknessMetricChanged"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" class="px-2 pr-7 pt-0">
                <v-autocomplete
                  :label="$t('sawCalc.inputMachine.bladeLengthMetricLabel')"
                  :items="bladeLengthMetricList"
                  :disabled="itemOverlay"
                  v-model="item.bladeLengthMetric"
                  @change="bladeLengthMetricChanged"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("sawCalc.inputMachine.machineOrientationLabel") }}
                </span>
              </v-col>
              <v-col cols="12" sm="9" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="item.machineOrientation"
                  mandatory
                  dense
                >
                  <v-btn
                    color="primary"
                    :disabled="itemOverlay"
                    @click="orientationChanged"
                  >
                    <span>
                      {{
                        $t(
                          "sawCalc.inputMachine.machineOrientationHorizontalLabel"
                        )
                      }}
                    </span>
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="itemOverlay"
                    @click="orientationChanged"
                  >
                    <span>
                      {{
                        $t(
                          "sawCalc.inputMachine.machineOrientationVerticalLabel"
                        )
                      }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("sawCalc.inputMachine.scissorPivotLabel") }}
                </span>
              </v-col>
              <v-col cols="12" sm="9" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="item.scissorPivot"
                  mandatory
                  dense
                >
                  <v-btn
                    color="primary"
                    :disabled="!scissorPivotEnabled || itemOverlay"
                  >
                    <span>
                      {{ $t("sawCalc.inputMachine.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="!scissorPivotEnabled || itemOverlay"
                  >
                    <span>
                      {{ $t("sawCalc.inputMachine.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("sawCalc.inputMachine.cantedHeadLabel") }}
                </span>
              </v-col>
              <v-col cols="12" sm="9" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="item.cantedHead"
                  mandatory
                  dense
                >
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("sawCalc.inputMachine.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("sawCalc.inputMachine.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="5" class="px-2 pt-0">
                <v-text-field
                  :label="$t('sawCalc.inputMachine.bandSpeedMinMetricLabel')"
                  :disabled="itemOverlay"
                  v-model="item.bandSpeedMinMetric"
                  :rules="itemInputRules"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="5" class="px-2 pt-0">
                <v-text-field
                  :label="$t('sawCalc.inputMachine.bandSpeedMaxMetricLabel')"
                  :disabled="itemOverlay"
                  v-model="item.bandSpeedMaxMetric"
                  :rules="itemInputRules"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('user.machines.cancelButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="itemOverlay"
                  @click="close()"
                ></v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('user.machines.saveButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="saveDisabled"
                  @click="saveMachine(item)"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserMachineImperial",

  props: {
    bladeWidthMetricList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeThicknessMetricList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeLengthMetricList: {
      type: Array,
      required: true,
      default: () => [],
    },
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      itemOverlay: false,
      itemInputRules: [
        (v) =>
          (!!v && v.length > 0) || this.$t("sawCalc.inputMachine.inputRules"),
      ],
    };
  },

  computed: {
    ...mapGetters(["machineImageMaxSize", "axiosSource"]),
    itemValid() {
      if (!this.item) {
        return false;
      }

      const bladeWidthMetric = this.item.bladeWidthMetric;
      const bladeThickness = this.item.bladeThicknessMetric;
      const bladeLength = this.item.bladeLengthMetric;
      const bandSpeedMin = this.item.bandSpeedMinMetric;
      const bandSpeedMax = this.item.bandSpeedMaxMetric;

      let valid =
        this.item != null &&
        this.item.machineName != undefined &&
        this.item.machineName != "" &&
        this.item.manufacturerName != undefined &&
        this.item.manufacturerName != "" &&
        this.item.model != undefined &&
        this.item.model != "" &&
        bladeWidthMetric != null &&
        bladeWidthMetric > 0 &&
        bladeThickness != null &&
        bladeThickness > 0 &&
        bladeLength != null &&
        bladeLength > 0 &&
        bandSpeedMin != null &&
        bandSpeedMin >= 0 &&
        bandSpeedMax != null &&
        bandSpeedMax > 0;

      return valid;
    },
    scissorPivotEnabled() {
      return this.item.machineOrientation == 0;
    },
    saveDisabled() {
      if (!this.itemValid || this.itemOverlay) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    bladeWidthMetricChanged(item) {
      if (item == undefined) {
        this.item.bladeWidthMetric = null;
        return;
      }

      this.item.bladeWidthMetric = item;
    },
    bladeThicknessMetricChanged(item) {
      if (item == undefined) {
        this.item.bladeThicknessMetric = null;
        return;
      }

      this.item.bladeThicknessMetric = item;
    },
    bladeLengthMetricChanged(item) {
      if (item == undefined) {
        this.item.bladeLengthMetric = null;
        return;
      }

      this.item.bladeLengthMetric = item;
    },
    bladeLengthInChanged(item) {
      if (item == undefined) {
        this.item.bladeLengthIn = null;
        return;
      }

      this.item.bladeLengthIn = item;
    },
    bladeLengthFrChanged(item) {
      if (item == undefined) {
        this.item.bladeLengthFr = null;
        return;
      }

      const bladeLengthFr = this.bladeLengthFrList.find(
        ({ value }) => value === item
      );

      this.item.bladeLengthFr = bladeLengthFr;
    },
    orientationChanged() {
      this.$store.dispatch("reloadMachineComponent", false);
    },
    saveMachine() {
      this.itemOverlay = true;

      let functionName = "";

      if (!this.item.userMachineID) {
        functionName = "createUserMachine";
      } else {
        functionName = "updateUserMachine";
      }

      this.$store
        .dispatch(functionName, {
          machine: this.item,
          uom: 1,
        })
        .then(
          (response) => {
            this.itemOverlay = false;

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };
            this.$store.dispatch("showSnackbar", notification);

            this.$refs.itemForm.reset();
            this.$emit("machinesUpdated", false);
          },
          (error) => {
            this.itemOverlay = false;

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }
            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };
            this.$store.dispatch("showSnackbar", notification);
          }
        );
    },
    close() {
      this.$emit("close", false);
    },
  },
};
</script>
