<template>
  <div>
    <v-data-table
      id="content"
      :headers="headers"
      :items="userMachines"
      :items-per-page="5"
      :search="search"
      sort-by="machineName"
      :loading="loading"
      :loading-text="$t('user.machines.loadingWait')"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title
            ><h2>{{ title }}</h2></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('user.machines.search')"
            single-line
            hide-details
            style="max-width: 300px;"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            text
            icon
            color="primary"
            :disabled="loading"
            @click="showSettings()"
          >
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
          <v-menu v-if="userLinks.length > 0" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                icon
                color="primary"
                :disabled="loading"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-text-account</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(itm, i) in userLinks"
                :key="`${i}-userLink-menu`"
                @click="showUserLink(itm)"
              >
                <v-list-item-title>{{ itm.linkName }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <DataTableUOMSettings
            :items="dataTableItems"
            :inputUOM="inputUOM"
            @updateSettings="updateSettings($event)"
            :key="dataTableUOMSettingsKey"
          />

          <v-dialog
            v-model="dialog"
            persistent
            :fullscreen="$vuetify.breakpoint.smAndDown"
            max-width="520px"
          >
            <Imperial
              v-if="!metric"
              :bladeWidthImperialList="bladeWidthImperialList"
              :bladeThicknessImperialList="bladeThicknessImperialList"
              :bladeLengthFtList="bladeLengthFtList"
              :bladeLengthInList="bladeLengthInList"
              :bladeLengthFrList="bladeLengthFrList"
              :item="editedItem"
              @close="close"
              @machinesUpdated="machinesUpdated"
              :key="unknownMachineImperialKey"
            />
            <Metric
              v-else
              :bladeWidthMetricList="bladeWidthMetricList"
              :bladeThicknessMetricList="bladeThicknessMetricList"
              :bladeLengthMetricList="bladeLengthMetricList"
              :item="editedItem"
              @close="close"
              @machinesUpdated="machinesUpdated"
              :key="unknownMachineMetricKey"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.imageID="{ item }">
        <div v-if="item.imageID">
          <div v-if="item.url" class="image-controls">
            <v-btn
              fab
              x-small
              light
              class="image-controls-buttons"
              :disabled="userLink != null"
              @click="removeImage(item)"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div v-else>
            <v-container fluid class="image-controls">
              <v-row>
                <v-col align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    class="image-controls-buttons"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
        <div v-else class="pb-3">
          <v-btn
            depressed
            tile
            v-html="$t('user.machines.addImageButton')"
            class="primary white--text"
            :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
            :disabled="userLink != null"
            @click="addImage(item)"
          ></v-btn>
        </div>
        <img
          v-if="item.imageID"
          :src="item.url"
          class="rounded-card mt-1"
          height="150"
          @click="openSawCalc(item)"
        />
      </template>
      <template v-slot:item.machineName="{ item }">
        <span class="primary--text saw-calc-link" @click="openSawCalc(item)">
          {{ item.machineName }}
        </span>
      </template>
      <template v-slot:item.details="{ item }">
        <MachineSelectorDetails :item="item" :inputUOM="uom" class="mt-1" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          class="mr-2"
          :disabled="userLink != null"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          class="mr-2"
          :disabled="userLink != null"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <span>{{ $t("user.machines.noMachinesFound") }}</span>
      </template>
    </v-data-table>

    <input
      type="file"
      style="display: none;"
      ref="fileInput"
      accept="image/png, image/jpeg"
      @change="fileSelected"
    />

    <v-overlay :value="itemOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import DataTableUOMSettings from "@/components/common/DataTableUOMSettings";
import Imperial from "@/components/user/Machine/Imperial";
import Metric from "@/components/user/Machine/Metric";
import MachineSelectorDetails from "@/components/public/SawCalc/Selectors/Machine/MachineSelectorDetails";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "MachinesUser",

  components: {
    DataTableUOMSettings,
    Imperial,
    Metric,
    MachineSelectorDetails,
  },

  data() {
    return {
      uom: 0,
      headersList: [
        {
          text: this.$t("user.machines.imageLabel"),
          value: "imageID",
          uom: null,
        },
        {
          text: this.$t("user.machines.machineNameLabel"),
          value: "machineName",
          uom: null,
        },
        {
          text: this.$t("user.machines.manufacturerNameLabel"),
          value: "manufacturerName",
          uom: null,
        },
        {
          text: this.$t("user.machines.modelLabel"),
          value: "model",
          uom: null,
        },
        {
          text: this.$t("user.machines.detailsLabel"),
          value: "details",
          align: "center",
          uom: null,
          width: 350,
        },
        {
          text: this.$t("user.machines.edit"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      headers: [],
      search: "",
      dialog: false,
      editedIndex: -1,
      editedItem: {
        machineID: 0,
        manufacturerID: 0,
        manufacturerName: "",
        model: "",
        bladeWidthIn: 0,
        bladeWidthFr: null,
        bladeWidthMetric: 0,
        bladeThicknessImperial: 0,
        bladeThicknessMetric: 0,
        bladeLengthFt: 0,
        bladeLengthIn: null,
        bladeLengthFr: null,
        bladeLengthImperial: 0,
        bladeLengthMetric: 0,
        hortizontal: false,
        scissorPivot: null,
        cantedHead: false,
        bandSpeedMinImperial: 0,
        bandSpeedMaxImperial: 0,
        bandSpeedMinMetric: 0,
        bandSpeedMaxMetric: 0,
      },
      defaultItem: {
        machineID: 0,
        manufacturerID: 0,
        manufacturerName: "",
        model: "",
        bladeWidthIn: 0,
        bladeWidthFr: null,
        bladeWidthMetric: 0,
        bladeThicknessImperial: 0,
        bladeThicknessMetric: 0,
        bladeLengthFt: 0,
        bladeLengthIn: null,
        bladeLengthFr: null,
        bladeLengthImperial: 0,
        bladeLengthMetric: 0,
        hortizontal: false,
        scissorPivot: null,
        cantedHead: false,
        bandSpeedMinImperial: 0,
        bandSpeedMaxImperial: 0,
        bandSpeedMinMetric: 0,
        bandSpeedMaxMetric: 0,
      },
      loading: false,
      dataTableItems: [],
      itemOverlay: false,
      userLink: null,
    };
  },

  computed: {
    ...mapGetters([
      "userMachines",
      "machinesUserSettings",
      "bladeWidthImperialList",
      "bladeThicknessImperialList",
      "bladeLengthFtList",
      "bladeLengthInList",
      "bladeLengthFrList",
      "bladeWidthMetricList",
      "dataTableUOMSettingsKey",
      "bladeThicknessMetricList",
      "bladeLengthMetricList",
      "unknownMachineImperialKey",
      "unknownMachineMetricKey",
      "inputUOM",
      "machineImageMaxSize",
      "user",
    ]),
    title() {
      if (this.userLink) {
        return this.userLink.linkName;
      } else {
        return this.$t("user.machines.title");
      }
    },
    userLinks() {
      if (this.user) {
        const userLinks = [...this.user.userLinks];
        userLinks.unshift({
          id: 0,
          userID: this.user.userID,
          linkName: this.$t("user.machines.myMachines"),
        });

        return userLinks;
      } else {
        return [];
      }
    },
    metric() {
      return this.uom > 0;
    },
  },

  methods: {
    setDefaults() {
      this.uom = this.inputUOM;
    },
    getUserMachines(showNotification) {
      this.loading = true;

      let userID = null;
      if (this.userLink) {
        userID = this.userLink.userID;
      }
      this.$store
        .dispatch("getUserMachines", {
          userID: userID,
          showNotification: showNotification,
        })
        .then(
          (response) => {
            this.loading = false;

            if (showNotification) {
              const notification = {
                show: true,
                result: true,
                message: response.message,
              };

              this.$store.dispatch("showSnackbar", notification);
            }

            this.updateHeaders(this.uom);

            let imagesList = this.userMachines
              .filter((item) => item.imageID != null && item.imageID > 0)
              .map((o) => ({
                userMachineID: o.userMachineID,
                fileName: o.fileName,
              }));

            if (imagesList.length > 0) {
              this.$store.dispatch("getUserMachineImages", {
                list: imagesList,
              });
            }
          },
          (error) => {
            this.loading = false;

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);

            if (api.authenticationError(error.code)) {
              setTimeout(function() {
                router.push({ name: "Home" }).catch(() => {});
              }, api.authErrorRedirectTimeout);
            }
          }
        );
    },
    showSettings() {
      this.updateDataTableItems();
      this.$store.dispatch("showDataTableUOMSettingsDialog", true);
    },
    updateSettings(params) {
      this.$store.dispatch("saveMachinesUserSettings", params);

      this.uom = params.uom;
      this.updateHeadersFromSettings(this.uom, params.headers);
    },
    updateHeaders(uom) {
      if (this.settings()) {
        this.updateHeadersFromSettings(
          this.uom,
          this.machinesUserSettings.headers
        );
      } else {
        this.headers = this.headersList.filter(
          (item) => item.uom == null || item.uom == uom
        );
      }
    },
    updateHeadersFromSettings(uom, headers) {
      var values = headers
        .filter(
          (item) => (item.uom == null || item.uom == uom) && item.selected
        )
        .map((item) => {
          return item.value;
        });

      this.headers = this.headersList.filter((item) =>
        values.includes(item.value)
      );
    },
    updateDataTableItems() {
      if (this.settings()) {
        this.uom = this.machinesUserSettings.uom;
        this.dataTableItems = this.machinesUserSettings.headers;
      } else {
        let items = this.headersList.map((o) => ({
          text: o.text,
          value: o.value,
          uom: o.uom,
          selected: true,
        }));

        items.sort(function(a, b) {
          if (!a.text || !b.text) {
            return 0;
          }

          var x = a.text.toLowerCase();
          var y = b.text.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        this.dataTableItems = items;
      }

      this.$store.dispatch("reloadMachinesUserSettingsComponent");
    },
    settings() {
      return (
        this.machinesUserSettings &&
        this.machinesUserSettings.uom != null &&
        this.machinesUserSettings.headers != null
      );
    },
    editItem(item) {
      this.editedIndex = this.userMachines.indexOf(item);
      this.editedItem = Object.assign({}, item);

      const bladeWidthImperial = this.bladeWidthImperialList.find(
        ({ value }) =>
          value == this.editedItem.bladeWidthIn + this.editedItem.bladeWidthFr
      );

      this.editedItem.bladeWidthImperial = bladeWidthImperial;

      const bladeLengthFr = this.bladeLengthFrList.find(
        ({ value }) => value == this.editedItem.bladeLengthFr
      );

      this.editedItem.bladeLengthFr = bladeLengthFr;
      this.editedItem.machineOrientation = this.editedItem.hortizontal ? 0 : 1;
      this.editedItem.scissorPivot = this.editedItem.scissorPivot ? 1 : 0;
      this.editedItem.cantedHead = this.editedItem.cantedHead ? 1 : 0;

      this.dialog = true;
    },
    deleteItem(item) {
      const action = this.$t("user.machines.deleteRecord");
      if (!confirm(action)) {
        return;
      }

      this.itemOverlay = true;

      this.$store.dispatch("removeUserMachine", item).then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.machinesUpdated();
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    machinesUpdated() {
      this.dialog = false;
      this.getUserMachines(false);
    },
    addImage(item) {
      this.editedIndex = this.userMachines.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$refs.fileInput.click();
    },
    fileSelected(event) {
      const file = event.target.files[0];

      if (file.size > this.machineImageMaxSize * 1024) {
        const notification = {
          show: true,
          result: false,
          message:
            this.$t("user.machines.maxImageSize") +
            ` ${this.machineImageMaxSize} KB. ` +
            this.$t("user.machines.cannotBeUploaded"),
        };

        this.$store.dispatch("showSnackbar", notification);

        this.$refs.fileInput.value = null;

        return;
      }

      this.editedItem.image = file;

      this.$refs.fileInput.value = null;

      this.$store.dispatch("getImageUrl", this.editedItem.image).then(
        (response) => {
          this.editedItem.url = response.url;
          this.updateImage();
        },
        (error) => {
          this.clearFileInput();

          // eslint-disable-next-line
          console.error("error", error);
        }
      );
    },
    clearFileInput() {
      this.editedItem.fileName = "";
      this.editedItem.url = "";
      this.editedItem.image = null;
    },
    updateImage() {
      this.itemOverlay = true;

      this.$store
        .dispatch("updateUserMachineImage", {
          userMachineID: this.editedItem.userMachineID,
          imageID: this.editedItem.imageID,
          file: this.editedItem.image,
        })
        .then(
          (response) => {
            this.itemOverlay = false;

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);
          },
          (error) => {
            this.itemOverlay = false;

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);

            // eslint-disable-next-line
            console.error("error", error);
          }
        );
    },
    removeImage(item) {
      const action = this.$t("user.machines.removeImage");
      if (!confirm(action)) {
        return;
      }

      this.itemOverlay = true;

      this.$store.dispatch("removeUserMachineImage", item).then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.machinesUpdated();
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    openSawCalc(item) {
      this.$store.dispatch("setSavedInputMachine", item).then(() => {
        router.push({ name: "SawCalc" }).catch(() => {});
      });
    },
    showUserLink(item) {
      if (item && item.id != 0) {
        this.userLink = item;
      } else {
        this.userLink = null;
      }

      this.getUserMachines(true);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  mounted() {
    this.setDefaults();
    this.getUserMachines(true);
  },
};
</script>

<style lang="scss" scoped>
.image-controls {
  position: relative;
  left: 5px;
  top: 7px;
  height: 0px;
}
.image-controls-buttons {
  color: map-get($theme, footer-link-color);
}
.saw-calc-link {
  cursor: pointer;
}
</style>
